import React from "react"
import { GrLinkNext } from "react-icons/gr"
import "../styles/footer.scss"
import Newsletter from "./newsletter"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function Footer() {
  function open() {
    var modal = document.getElementsByClassName("modal-newsletter")[0]
    modal.classList.add("activo")
  }

  return (
    <>
      <footer className="">
        <div className="footer-principal container">
          <div className="footer-col col-1">
            <div className="logo-footer">
              <img src="/img/bullhost-white.svg" alt="Bullhost" />
            </div>
            <div>
              Servicios robustos y ciberseguros liderados por un equipo 10 y las
              tecnologías más top del mercado.
            </div>
            <div className="footer-newsletter">
              <p>
                <Link to="/contacto/">
                  Contacta con nosotros <GrLinkNext />
                </Link>
              </p>
            </div>
            <div
              role="button"
              tabIndex="0"
              className="footer-newsletter"
              onClick={() => open()}
              onKeyDown={() => open()}
            >
              <p>
                Apúntate a nuestra newsletter <GrLinkNext />
              </p>
            </div>
          </div>
          <div className="footer-col col-2">
            <p>Navegación</p>
            <ul className="navegacion">
              <li>
                <Link to="/cloud-robusto/">Cloud robusto</Link>
              </li>
              <li>
                <Link to="/proyectos/">Proyectos</Link>
              </li>
              <li>
                <Link to="/fabricantes/">Fabricantes</Link>
              </li>
              <li>
                <Link to="/blog/">Blog</Link>
              </li>
              <li>
                <Link to="/contacto/">Contacto</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col col-3">
            <p>Servicios destacados</p>
            <ul>
              <li>
                <Link to="/servicios/ciberseguridad/hacking-etico/auditorias-ciberseguridad/">
                  Auditorías de ciberseguridad
                </Link>
              </li>
              {/* <li><Link to="/servicios/ciberseguridad/ciberseguridad-industrial/monitorizacion-red-ot">Monitorización red OT</Link></li> */}
              <li>
                <Link to="/servicios/ciberseguridad/defensivos/securizacion-dominios/">
                  Securización de dominios
                </Link>
              </li>
              <li>
                <Link to="/servicios/ciberseguridad/defensivos/formacion-concienciacion/">
                  Formación y concienciación
                </Link>
              </li>
              <li>
                <Link to="/servicios/cloud/hosting-web/">Hosting web</Link>
              </li>
              <li>
                <Link to="/servicios/cloud/backup/">
                  Copias de seguridad: backup
                </Link>
              </li>
              <li>
                <Link to="/servicios/sistemas-informaticos-avanzados/sistemas-informaticos/">
                  Sistemas informáticos
                </Link>
              </li>
              <li>
                <Link to="/servicios/sistemas-informaticos-avanzados/outsourcing-tecnologico/">
                  Outsourcing tecnológico
                </Link>
              </li>
              <li>
                <Link to="/servicios/compliance/">Compliance</Link>
              </li>
              <li>
                <a
                  href="https://bullhost.agency/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Marketing y Web
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-col col-4">
            <p>Legal</p>
            <ul>
              <li>
                <Link to="/aviso-legal/">Aviso legal</Link>
              </li>
              <li>
                <Link to="/politica-de-privacidad/">
                  Política de privacidad
                </Link>
              </li>
              <li>
                <Link to="/politica-de-cookies/">Política de cookies</Link>
              </li>
              <li>
                <Link to="/politica-de-seguridad-de-la-informacion/">
                  Política de seguridad de la información
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-col col-5">
            <p>Social</p>
            <ul>
              <li>
                <a
                  href="https://twitter.com/bull_host/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://es.linkedin.com/company/bullhost-cloud-services"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="avisos">
          <div className="logos-certificaciones">
            <StaticImage
              src="../../static/img/logos-footer/ISO-27001-150-alto.png"
              style={{ maxHeight: 130 }}
              objectFit="contain"
              alt="ISO-27001"
            />
            <a
              href="https://www.incibe.es/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <StaticImage
                src="../../static/img/logos-footer/logo-incibe-40.png"
                style={{ maxHeight: 40 }}
                objectFit="contain"
                alt="Logo INCIBE"
              />
            </a>
            <a
              href="https://www.ciberseguridad.eus/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <StaticImage
                src="../../static/img/logos-footer/logo-basque-65.png"
                style={{ maxHeight: 65 }}
                objectFit="contain"
                alt="Logo Basque CyberSecurity Centre"
              />
            </a>
            <a
              href="https://rns.ccn-cert.cni.es/es/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <StaticImage
                src="../../static/img/logos-footer/rns_logo.png"
                style={{ maxHeight: 65 }}
                objectFit="contain"
                alt="Bullhost pertenece a la Red Nacional de SOC"
              />
            </a>
            <a
              href="https://www.ripe.net/membership/indices/data/es.bullhost.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              <StaticImage
                src="../../static/img/logos-footer/logo-RIPE-NCC-Member.png"
                style={{ maxHeight: 65, maxWidth: 250 }}
                objectFit="contain"
                alt="Bullhost es miembro de RIPE"
              />
            </a>
            <a
              href="https://dca.cat/ca/membre/bullhost-cloud-services/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <StaticImage
                src="../../static/img/logos-footer/DCA_LOGOTIPS_15_250.png"
                style={{ maxWidth: 200 }}
                objectFit="contain"
                alt="Bullhost es miembro de DCA"
              />
            </a>
          </div>
          <div id="kit-digital">
            <Link to="/kit-digital/">
              <StaticImage
                src="../../static/img/landings/kitdigital/kitdigital-logo.webp"
                style={{ maxWidth: 150 }}
                objectFit="contain"
                alt="Logo Kit Digital"
              />
            </Link>
            <p>Gestionamos tu subvención de Kit Digital</p>
          </div>
        </div>
      </footer>
      <div className="modal-newsletter">
        <Newsletter />
      </div>
    </>
  )
}
