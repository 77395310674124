import CookieConsent from "react-cookie-consent"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useLocation } from "@reach/router"
import React from "react"
import { Link } from "gatsby"

export default function BarraCookies() {
  const location = useLocation()
  return (
    <CookieConsent
      location="bottom"
      buttonText="Aceptar"
      ariaAcceptLabel="Aceptar cookies"
      ariaDeclineLabel="Rechazar cookies"
      declineButtonText="Rechazar"
      enableDeclineButton
      cookieName="gatsby-gdpr-google-tagmanager"
      buttonStyle={{
        fontFamily: "Now-black-light",
        backgroundColor: "#ac2328",
      }}
      onAccept={() => {
        initializeAndTrack(location)
      }}
    >
      Utilizamos cookies propias y de terceros para analizar el tráfico en
      nuestra web mediante la obtención de los datos necesarios para estudiar su
      navegación. Consulta nuestra{" "}
      <Link to="/politica-de-cookies/">política de cookies</Link>
    </CookieConsent>
  )
}
