import React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import "../styles/fuentes.scss"
import "../styles/generales.scss"
import "../styles/headerPaginas.scss"
import "../styles/layout.scss"
import FlechaSubida from "../components/flechaSubida"
import { useLocation } from "@reach/router"
import { motion } from "framer-motion"
import Buscar from "./buscar/buscar"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"
import BarraCookies from "./barraCookies"

const searchIndices = [
  { name: `bullhost_security`, title: `bullhost_security` },
]

export default function Layout(props) {
  const location = useLocation()



  if (location.pathname === "/") {
    return (
      <>
        {/*<SimpleBar className="contenedor-scrollbar">*/}
        <Header loc={location} />
        <Buscar indices={searchIndices} />
        <BarraCookies />
        <div className="contenido">
          {props.children}
          <FlechaSubida showBelow={800} />
        </div>
        <Footer />
        {/*</SimpleBar>*/}
      </>
    )
  }
  return (
    <>
      {/*<SimpleBar className="contenedor-scrollbar">*/}
      <Header loc={location} />
      <Buscar indices={searchIndices} />
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          // type: "spring",
          // damping:20,
          // stiffness: 75,
          duration: 0.5,
        }}
      >
        <BarraCookies />
        <div className="contenido">
          {props.children}
          <FlechaSubida showBelow={800} />
        </div>
      </motion.main>
      <Footer />
      {/*</SimpleBar>*/}
    </>
  )
}
